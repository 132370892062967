import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "dashboard.default",
    path: `${APP_PREFIX_PATH}/guest/chat/*`,
    component: React.lazy(() => import("views/app-views/chat/index")),
  },
  {
    key: "dashboard.default",
    path: `${APP_PREFIX_PATH}/admin/chat/*`,
    component: React.lazy(() => import("views/app-views/chat/index")),
  },
];

export const protectedRoutes = [
  {
    key: "dashboard.default",
    path: `${APP_PREFIX_PATH}/admin/chat/*`,
    component: React.lazy(() => import("views/app-views/chat/index")),
  },
  {
    key: "dashboard.default",
    path: `${APP_PREFIX_PATH}/guest/chat/*`,
    component: React.lazy(() => import("views/app-views/chat/index")),
  },
];
